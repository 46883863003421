import React, {ReactElement, useEffect, useReducer} from 'react';
import {StandardModal} from './StandardModal';
import {Form, FormField, ColumnLayout, Input, Textarea, Select, Flash} from '@amzn/awsui-components-react/polaris';
import I18n from '../../utils/setupI18n';
import TicketListApiClient from "../TicketList/TicketListApiClient";
import {History} from 'history';
import TicketCreateModalReducer from "./TicketCreateModalReducer";
import {ATMSJobFields, CreateTicketRequest, Roles, User} from "../types/commonTypes";
import {CREATED_MESSAGE} from "../types/constants";
import {withRouter} from "react-router";
import {AuthenticatedSession} from "@amzn/et-console-components";

interface Props {
    modalId?: string;
    submitButtonId?: string;
    visible: boolean;
    urlParams: Map<string, string>;
    onDismiss: () => void;
    session: AuthenticatedSession;
    history: History;
}

const initialState = {
    submitterId: "",
    submitterName: "",
    submitterEmail: "",
    title: "",
    description: "",
    categoryId: "",
    categoryName: "",
    showFlashBar: false,
    atmsJobDetails: "",
    isSubmitting: false
};

const TicketCreateModal = ({
                               modalId,
                               submitButtonId,
                               visible,
                               urlParams,
                               onDismiss,
                               session,
                               history
                           }: Props): ReactElement => {


    const user: User = session.user as User;
    const ticketsApi: TicketListApiClient = new TicketListApiClient();
    const [state, dispatch] = useReducer(TicketCreateModalReducer, initialState);
    const {
        submitterId, submitterName, submitterEmail, title, description,
        categoryId, categoryName, showFlashBar, atmsJobDetails, isSubmitting, ticketCategories
    } = state;
    const jobDetailsHeading: string = "ATMS Job details";

    useEffect(() => {
        dispatch({
            type: "userInfoAdded",
            submitterId: user.id.toString(),
            submitterName: user.userName,
            submitterEmail: user.email
        });
        if (Object.keys(urlParams).length !== 0) {
            let jobDetails: string = jobDetailsHeading;
            let projectId: string = "";
            Object.keys(urlParams).forEach(key => {
                if(ATMSJobFields[key] == "Project ID"){
                    projectId = urlParams[key]}
                jobDetails = jobDetails + "\n" + ATMSJobFields[key] + ": " + urlParams[key];
            });

            if(projectId != "") {
                jobDetails += "\n\n" + "Job Link: https://" + window.location.hostname + "/web/project2/show/" + projectId
            }
            dispatch({type: "receivedATMSJobDetails", atmsJobDetails: jobDetails});
        }
        // Load ticket categories
        loadTicketCategories()
    }, [session, urlParams]);

    const handleOptionChange = (e) => {
        dispatch({type: "inputChanged", component: "categoryId", value: e.detail.selectedOption.id});
        dispatch({type: "inputChanged", component: "categoryName", value: e.detail.selectedOption.label});
        getTemplate(e);
    };

    const handleChange = (e) => {
        dispatch({type: "inputChanged", component: e.target.id.toString(), value: e.detail.value});
    };

    const handleCancelClick = (): void => {
        onDismiss();
    };

    const handleSubmitClick = (e): void => {
        dispatch({type: "ticketCreateClicked"});
        e.preventDefault();
        if (title != undefined && title.trim().length > 0 &&
            description != undefined && description.trim().length > 0 &&
            categoryName != undefined && categoryName.trim().length > 0 &&
            categoryId != undefined && categoryId.trim().length > 0 &&
            submitterName != undefined && submitterName.trim().length > 0 &&
            submitterEmail != undefined && submitterEmail.trim().length > 0 &&
            submitterId != undefined && submitterId.trim().length > 0) {

            let createTicketRequest: CreateTicketRequest = {
                categoryId: categoryId,
                categoryName: categoryName,
                description: description,
                submitterEmail: submitterEmail,
                submitterId: submitterId,
                submitterName: submitterName,
                title: title
            };

            if(user.role == Roles.LINGUIST){
                createTicketRequest.linguistId = submitterId;
                createTicketRequest.linguistEmail = submitterEmail;
                createTicketRequest.linguistName = submitterName;
            }
            createNewTicket(createTicketRequest);
        }
    };

    const loadTicketCategories = async () => {
        const categories = await ticketsApi.getTicketCategories()
        dispatch({type: "loadedTicketCategories", ticketCategories: categories})
    }

    const getTemplate = async (e) => {
        const response = await ticketsApi.getTemplateApi(e.detail.selectedOption.id);
        if (response != null) {
            let updatedDescription = response.description;
            if (atmsJobDetails != undefined && atmsJobDetails.trim().length > 0)
                updatedDescription = atmsJobDetails + "\n\n" + updatedDescription;

            dispatch({type: "inputChanged", component: "description", value: updatedDescription});
            dispatch({type: "inputChanged", component: "title", value: response.templateTitle});
        }
    };

    const createNewTicket = async (data) => {
        const response = await ticketsApi.CreateTicketApi(JSON.stringify(data));
        if (response['atmsTicketId'] != undefined) {
            history.push("/web/tickets/"+response['atmsTicketId'], {
                showFlashBar: true,
                message: CREATED_MESSAGE});

            let announcementMessage = `ATMSTickets link:\n${window.location.href}`;
            const data = JSON.stringify({
                message: announcementMessage
            });
            await ticketsApi.postTicketAnnouncement(response['atmsTicketId'], data);

            onDismiss();
            dispatch({type: "ticketCreateComplete"});
        } else
            dispatch({type: 'errorMessageShowed'});
    };


    return (
        <div>
            <StandardModal
                id={modalId}
                header={I18n.t('Create Ticket')}
                content={
                    <Form>
                        <Flash visible={showFlashBar} type="error">
                            Unable to create a ticket. Please try again
                        </Flash>
                        <br/>
                        <ColumnLayout>
                            <div data-awsui-column-layout-root="true">
                                <FormField label="Problem Type"
                                           description="What type of problem are you experiencing?">
                                    <Select id="category" options={ticketCategories ?? []}
                                            onChange={handleOptionChange}
                                            loading={ticketCategories === undefined}></Select>
                                </FormField>
                                <FormField label="Title" description="Type a brief title for your ticket">
                                    <Input id="title" value={title} onChange={handleChange}></Input>
                                </FormField>
                                <FormField  label="Description" description="Fully describe the problem">
                                    <Textarea id="description" value={description} onChange={handleChange}></Textarea>

                                </FormField>
                            </div>
                        </ColumnLayout>
                        <br/>

                    </Form>
                }
                visible={visible}
                handleCancelClick={handleCancelClick}
                handleSubmitClick={handleSubmitClick}
                submitButtonId={submitButtonId}
                loading={isSubmitting}
            />
        </div>

    );
};

// @ts-ignore
export default withRouter(TicketCreateModal);