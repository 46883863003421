import React, {ReactElement, useEffect, useReducer} from "react";
import {PageHeader} from "@amzn/et-polaris-utils";
import I18n from '../../utils/setupI18n';
import TicketTabsContainer from "./TicketTabsContainer";
import {BreadcrumbGroup} from "@amzn/awsui-components-react";
import TicketCreateModal from "../components/TicketCreateModal";
import TicketCreateReducer from "./TicketCreateReducer";

const items = [{
    "text": I18n.t("ATMS"),
    "href": "/web"
},{
    "text": I18n.t("Support"),
    "href": "#"
}];

const TicketListComponent = (props): ReactElement => {


    const [state, dispatch] = useReducer(TicketCreateReducer, {isVisible: false, urlParams: {}});
    const {isVisible, urlParams} = state;


    useEffect(() => {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        let atmsJobDetails = {};
        params.forEach(function (value, key) {
           atmsJobDetails[key] = value;
        });

        if(Object.keys(atmsJobDetails).length > 0){
            dispatch({type: "createTicketLinkClicked", urlParams: atmsJobDetails})
        }

    },[]);

    const onDismissCreateTicketModal = (): void => {
        dispatch({type: "cancelTicketClicked"})
    };

    const handleCreationPopupButtonClicked = (): void => {
        dispatch({type: "creationPopupButtonClicked"})
    };

    return (<div id="TicketListContainer">
            <BreadcrumbGroup label={I18n.t("Breadcrumbs")} items={items}></BreadcrumbGroup>

            <PageHeader title={I18n.t("Support")}  buttons={[
                {
                    id: 'newTicket',
                    text: I18n.t('Create Ticket'),
                    variant: 'primary',
                    onClick: handleCreationPopupButtonClicked
                },
            ]}/>
            <TicketTabsContainer session={props.session}/>
            <TicketCreateModal
                modalId={`CreateTicketModal`}
                submitButtonId={`SubmitButton`}
                visible={isVisible}
                urlParams={urlParams}
                onDismiss={onDismissCreateTicketModal}
                session={props.session}
            />
        </div>
    );
};

export default TicketListComponent;