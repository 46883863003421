import React, { ReactElement } from 'react';
import getAppHostConfig from "../../shared/session/getAppHostConfig";

const Redirection = (props): ReactElement => {
    const { WEB_HOST_AND_PORT } = getAppHostConfig();

    window.location.replace(
        `//${WEB_HOST_AND_PORT}${props.path}`
    );
    return (<div />);
};

export default Redirection;