import {Ticket} from "../types/commonTypes";

export default class TicketDetailApiClient {
    getTicketDetail = async (ticketId: string): Promise<Ticket> => {
        let ticket: Ticket = {};
        const url = `/tickets/api/${ticketId}`;
        await fetch(url)
            .then(response => response.json())
            .then((data) => {
                if (data != undefined && data['ticketMetadata'] != undefined) {
                    ticket = data['ticketMetadata'] as Ticket;
                    ticket.comments = data['commentList'];
                }
            }, (error) => {
                console.log(error);
            });
        return ticket;
    };

    // Implement Ticket comment post request
    postTicketComment = async (ticketId: string, data) => {
        let response = {};
        const url = `/tickets/api/${ticketId}/comments`;
        await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
            }).catch(error => {
                console.log(error);
            });
        return response;
    };

    updateTicketComment = async (ticketId: string, commentId: string, data) => {
        let response = {};
        const url = `/tickets/api/${ticketId}/updateComment/${commentId}`;
        await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
            }).catch(error => {
                console.log(error);
            });
        return response;
    };

    updateTicketDescription = async (ticketId: string, data) => {
        let response = {};
        const url = `/tickets/api/${ticketId}/updateDescription/`;
        await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
            }).catch(error => {
                console.log(error);
            });
        return response;
    };

    updateTicketCategory = async (ticketId: string, data) => {
        let response = {};
        const url = `/tickets/api/${ticketId}/updateCategory/`;
        await fetch(url, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: data
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
            }).catch(error => {
                console.log(error);
            });
        return response;
    };

    submitTicket = async (ticketId: string, data) => {
        let response = {};
        const url = `/tickets/api/${ticketId}/submitTicket/`;
        await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
            }).catch(error => {
                console.log(error);
            });
        return response;
    };

    getUrls = async (fileName: string, fileExtension: string) => {
        const url = `/tickets/api/attachment/link?fileName=${fileName}&fileExtension=${fileExtension}`;
        let response = {};

        await fetch(url).then(response => response.json())
            .then((data) => {
                if(data['uploadLink'] != undefined && data['downloadLink'] != undefined)
                    response = data;
                else
                    throw new Error("Invalid payload from the server ");
            }).catch(error => {
                console.log("Unable to receive the preSigned url : "+error);
            });

        return response;
    };

    uploadFileToSIM = async(ticketId: string, formData: FormData) => {
        let response = {};
        const url = `/tickets/api/${ticketId}/attachment`;
        await fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data)
            }).catch(error => {
                console.log(error);
            });
        return response;
    };

    getTicketCategories = async () => {
        const url = "/tickets/api/categories"
        const response = await fetch(url)
        return response.json()
    }

};