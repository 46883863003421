import {Ticket} from "../types/commonTypes";


interface ReducerState {
    isDisplayCommentModal: boolean;
    ticketStatus: string;
    ticketDTO: Ticket;
    editMode: boolean;
    displayFlashbar:boolean;
    flashbarText:string;
    ticketCategories?: {label:string, id: number}[]
}

const TicketDetailReducer = (state, action): ReducerState => {
    switch (action.type) {
        case 'loadedTicketCategories':
            return {
                ...state,
                ticketCategories: action.ticketCategories
            }
        case 'ticketDetailsLoaded':
            return {
                ...state,
                ticketDTO: action.ticketDTO
            };
        case 'commentButtonClicked':
            return  {
                ...state,
                ticketStatus: "Open",
                isDisplayCommentModal: true,
            };
        case 'resolveButtonClicked':
            return{
                ...state,
                ticketStatus: "Resolved",
                isDisplayCommentModal: true,
            };
        case 'cancelCommentClicked':
            return {
                ...state,
                isDisplayCommentModal: false,
            };
        case 'submitCommentClicked':
            return {
                ...state,
                isDisplayCommentModal: false,
            };
        case 'toggleEditMode':
            return{
                ...state,
                editMode: action.editMode,
            };
        case 'toggleFlashbar':
            return{
                ...state,
                displayFlashbar:true,
                flashbarText:action.flashbarText,
            };
        default:
            return state;
    }
};

export default TicketDetailReducer;