
interface ReducerState {
    isVisible: boolean
    urlParams: object
}

const TicketCreateReducer = (state, action): ReducerState => {

    switch(action.type) {
        case 'createTicketLinkClicked':
            return {
                ...state,
                isVisible: true,
                urlParams: action.urlParams,
            };
        case 'creationPopupButtonClicked':
            return  {
                ...state,
                isVisible: true,
                urlParams: new Map<string, string>(),
            };
        case 'cancelTicketClicked':
            return {
                ...state,
                isVisible: false,
                urlParams: new Map<string, string>(),
            };
        case 'submitTicketClicked':
            return {
                ...state,
                isVisible: false,
                urlParams: new Map<string, string>(),
            };
        default:
            return state;
    }
};

export default TicketCreateReducer;