/*
 * Get useful hostnames for atms-web-ui / web / etc.
 *
 * Used by:
 *  - the app for redirect/embedding logic
 *  - the local proxy for atms-web-ui <=> web happiness in local/docker development
 */
const getAppHostConfig = (host = window.location.hostname, port = window.location.port) => {
  const BASE_HOST = host.replace(/^new\./, '');
  const portSuffix = port ? `:${port}` : '';

  const ATMS_WEB_UI_HOST_AND_PORT = `${BASE_HOST}${portSuffix}`;

  return {
    // canonical host+port for this app (e.g. local.atms.a2z.com:3001)
    ATMS_WEB_UI_HOST_AND_PORT,
    // common domain for cookies (e.g. local.atms.a2z.com)
    BASE_HOST,
    // where top-level unembedded old-style web lives
    WEB_HOST_AND_PORT: `web.${BASE_HOST}${portSuffix}`,
  };
};

export default getAppHostConfig;
