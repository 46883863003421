import React, {ReactElement, useCallback, useReducer, useState} from "react";
import ReactS3Uploader from "react-s3-uploader";
import TicketDetailApiClient from "./TicketDetailApiClient";

const FileAttachmentContainer = (props): ReactElement => {
    let downloadUrl, uploadUrl, fileName;
    const uploadCompletionEvent = "Upload completed";
    const ticketDetailApi: TicketDetailApiClient = new TicketDetailApiClient();

    // function to get the urls
    const getUrls = async (file, callback) => {
        fileName = file.name;
        let fileExtension = (file.name.lastIndexOf(".") > -1) ?
            file.name.substr(file.name.lastIndexOf(".")+1) : "txt";
        const response = await ticketDetailApi.getUrls(fileName,fileExtension);
        if(response['uploadLink'] != undefined){
            downloadUrl = response['downloadLink'];
            uploadUrl = response['uploadLink'];
            callback({signedUrl : uploadUrl});
        }
    };

    const postLinkAsComment = () =>{
        const commentData = JSON.stringify({
            commentType: "attachment",
            authorName: props.ticket.submitterName,
            commentText: JSON.stringify({
                "fileName": fileName,
                "downloadUrl": downloadUrl,

            })
        });
        ticketDetailApi.postTicketComment(props.ticket.atmsTicketId, commentData)
            .then(() => {
                props.updateHistorySection(props.ticket.atmsTicketId);
            }).catch((error) => {
            console.log(" Unable to post the url as comment");
        });
    };

    const uploadFileToSIM = async (percent, status, file) => {
        if(status == uploadCompletionEvent){
            let formData = new FormData();
            formData.append("file", file);
            await ticketDetailApi.uploadFileToSIM(props.ticket.atmsTicketId, formData);
        }
    };

    return (<ReactS3Uploader id="fileAttachment"
                             getSignedUrl={getUrls}
                             uploadRequestHeaders={{}} onProgress={uploadFileToSIM} onFinish={postLinkAsComment} style={{display: 'none'}}/>);

};


export default FileAttachmentContainer;