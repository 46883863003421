interface ReducerState{
    submitterId?: string,
    submitterName?: string,
    submitterEmail?: string
    title?: string,
    description?: string,
    categoryId?: string,
    categoryName?: string,
    showFlashBar?: boolean
    atmsJobDetails?: string,
    isSubmitting: boolean,
    ticketCategories?: {label:string, id: number}[]
}

const TicketCreateModalReducer = (state, action): ReducerState => {
    switch(action.type) {
        case 'loadedTicketCategories':
            return {
                ...state,
                ticketCategories: action.ticketCategories
            }
        case 'receivedATMSJobDetails':
            return{
                ...state,
                atmsJobDetails: action.atmsJobDetails,
                description: action.atmsJobDetails,
            };
        case 'errorMessageShowed':
            return{
                ...state,
                showFlashBar: true,
                isSubmitting: false,
            };
        case 'userInfoAdded':
            return {
                ...state,
                submitterId: action.submitterId,
                submitterName: action.submitterName,
                submitterEmail: action.submitterEmail
            };
        case 'inputChanged':
            return  {
                ...state,
                [action.component]: action.value,
            };
        case 'ticketCreateClicked':
            return  {
                ...state,
                isSubmitting: true,
            };
        case 'ticketCreateComplete':
            return  {
                ...state,
                isSubmitting: false,
            }
        default:
            return state;
    }
};

export default TicketCreateModalReducer;