import React, {ReactElement, useCallback, useReducer} from 'react';
import {
    Select,
    ColumnLayout,
} from '@amzn/awsui-components-react/polaris';
import { PageHeader } from '@amzn/et-polaris-utils';
import I18n from '../../utils/setupI18n';
import {FormField} from "@amzn/awsui-components-react";
import {Roles} from "../types/commonTypes";
import {LINGUIST_TICKET_FOLDER} from "../types/constants";

const TicketMetadataComponent = (props): ReactElement => {

    const handleOptionChange = (e) => {
        props.ticket.category = e.detail.selectedOption.label;
        props.ticket.categoryId = e.detail.selectedOption.id;
    };

    return (
        <div id="TicketMetadataContainer">

            {/*Non-Lingust tickets*/}
            {props.ticket.status != 'Resolved' && props.ticket.assignedFolder != LINGUIST_TICKET_FOLDER &&
            <PageHeader title={I18n.t('Ticket Details')} buttons={[
                {
                    id: 'changeTicketStatus',
                    text: I18n.t('Resolve Ticket'),
                    variant: 'normal',
                    onClick: props.handleResolveFunction
                },
            ]}/>
            }

            {props.ticket.status == 'Resolved' && props.ticket.assignedFolder != LINGUIST_TICKET_FOLDER &&
            <PageHeader title={I18n.t('Ticket Details')} buttons={[
                {
                    id: 'changeTicketStatus',
                    text: I18n.t('Reopen Ticket'),
                    variant: 'normal',
                    onClick: props.handleCommentFunction
                },
            ]}/>
            }

            {/*Linguist tickets*/}
            {props.ticket.status != 'Resolved' &&
            props.ticket.assignedFolder == LINGUIST_TICKET_FOLDER
            && props.session.user.role != Roles.LINGUIST
            && !props.editModeEnabled &&
            <PageHeader title={I18n.t('Ticket Details')} buttons={[
                {
                    id: 'toggleEditMode',
                    text: I18n.t('Edit'),
                    variant: 'normal',
                    onClick: props.handleToggleEditFunction
                },
                {
                    id: 'submitLinguistTicket',
                    text: I18n.t('Send to Support'),
                    variant: 'normal',
                    onClick: props.handleSubmitTicket
                },
                {
                    id: 'changeTicketStatus',
                    text: I18n.t('Resolve Ticket'),
                    variant: 'normal',
                    onClick: props.handleResolveFunction
                },
            ]}/>
            }

            {props.ticket.status == 'Resolved' &&
            props.ticket.assignedFolder == LINGUIST_TICKET_FOLDER
            && props.session.user.role != Roles.LINGUIST
            && !props.editModeEnabled &&
            <PageHeader title={I18n.t('Ticket Details')} buttons={[
                {
                    id: 'changeTicketStatus',
                    text: I18n.t('Reopen Ticket'),
                    variant: 'normal',
                    onClick: props.handleCommentFunction
                },
            ]}/>
            }

            {props.ticket.status != 'Resolved' &&
            props.ticket.assignedFolder == LINGUIST_TICKET_FOLDER &&
            props.session.user.role == Roles.LINGUIST &&
            !props.editModeEnabled &&
            <PageHeader title={I18n.t('Ticket Details')} buttons={[
                {
                    id: 'toggleEditMode',
                    text: I18n.t('Edit'),
                    variant: 'normal',
                    onClick: props.handleToggleEditFunction
                }
            ]}/>
            }

            {props.ticket.status != 'Resolved' &&
            props.ticket.assignedFolder == LINGUIST_TICKET_FOLDER &&
            props.editModeEnabled &&
            <PageHeader title={I18n.t('Ticket Details')} buttons={[
                {
                    id: 'toggleEditMode',
                    text: I18n.t('Save Changes'),
                    variant: 'normal',
                    onClick: props.handleSaveLinguistTicket
                },
            ]}/>
            }


            <div className='awsui-util-container'>
                <div className='awsui-util-container-header'>
                    <h2>{props.ticket.title}</h2>
                </div>

                <ColumnLayout columns={3} variant="text-grid">
                    <div data-awsui-column-layout-root="true">
                        <div>
                            <div className='awsui-util-label'>Submitter Name</div>
                            <div>
                                {props.ticket.submitterName}
                            </div>
                        </div>
                        <div>
                            <div className='awsui-util-label'>Created on</div>
                            <div>
                                {props.ticket.createDate}
                            </div>
                        </div>
                        <div>
                            <div className='awsui-util-label'>Status</div>
                            <div>
                                {props.ticket.status}
                            </div>
                        </div>
                        <div>
                            <div className='awsui-util-label'>Category</div>
                            <div>
                                {props.ticket.assignedFolder == LINGUIST_TICKET_FOLDER && props.editModeEnabled &&
                                <FormField>
                                    <Select id="category" placeholder={props.ticket.category} options={props.ticketCategories ?? []} loading={props.ticketCategories === undefined} onChange={handleOptionChange}></Select>
                                </FormField>
                                }
                                {(props.ticket.assignedFolder != LINGUIST_TICKET_FOLDER || !props.editModeEnabled) &&
                                props.ticket.category
                                }
                            </div>
                        </div>

                        <div>
                            <div className='awsui-util-label'>Last Updated</div>
                            <div>
                                {props.ticket.lastUpdatedDate}
                            </div>
                        </div>
                        <div>
                            <div className='awsui-util-label'>Action</div>
                            <div>
                                {props.ticket.action}
                            </div>
                        </div>
                    </div>
                </ColumnLayout>

            </div>
        </div>
    );
};

export default TicketMetadataComponent;
