import {Ticket} from "../types/commonTypes";


interface ReducerState {
    areTicketsLoading: boolean;
    ticketLoadError?: string;
    tickets: Ticket[];
    currentView: string;
}

const TicketListReducer = (state, action): ReducerState => {
    switch(action.type) {
        case 'ticketsReceived':
            return  {
                ...state,
                tickets: action.tickets,
                currentView: "myTickets",
                areTicketsLoading: false,
            };
        case 'ticketLoadFailed':
            return {
                ...state,
                areTicketsLoading: false,
                ticketLoadError: action.error
            }
        case 'allTicketsClicked':
            return {
                ...state,
                tickets: state.tickets,
                currentView: "allTickets"

            };
        case 'myTicketsClicked':
            return {
                ...state,
                tickets: state.tickets,
                currentView: "myTickets"
            };
        default:
            return state;
    }
};


export default TicketListReducer;