export const INVALID_PAYLOAD = "Invalid payload";
export const UNAUTHORIZED = "Unauthorized" ;
export const AUTHENTICATED = "Authenticated" ;
export const BACKEND_ERROR = "Something went wrong in the backend";

export const ESCALATION_MESSAGE = "Successfully escalated ticket to ATMS Support. We will get back to you as soon as we can.";
export const RESOLVED_MESSAGE = "Successfully resolved ticket.";
export const CREATED_MESSAGE = "Successfully created a ticket. Our support team will respond to your ticket as soon as they can.";

export const LINGUIST_TICKET_FOLDER = "1a6d0779-7775-4357-a27f-76b77c9d0716";
