interface ModalState {
    authorName: string,
    commentText: string,
    commentType: string,
    ticketStatus: string,
    flashBar : boolean,
    isSubmitting: boolean
}

const TicketCommentModalReducer = (state, action): ModalState => {
    switch(action.type) {
        case 'commentChanged':
            return  {
                ...state,
                commentText: action.commentText
            };
        case 'commentSubmitting':
            return {
                ...state,
                isSubmitting: true
            }
        case 'commentSubmitted':
            return {
                ...state,
                commentText: "",
                isSubmitting: false
            };
        case 'authorInfoSubmitted':
            return {
                ...state,
                authorName: action.authorName
            };
        case 'toggleFlashOff':
            return{
                ...state,
                flashBar: false
            };
        case 'toggleFlashOn':
            return{
                ...state,
                flashBar: true,
                isSubmitting: false
            };
        default:
            return state;
    }
};

export default TicketCommentModalReducer;
