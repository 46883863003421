import React, {ReactElement} from 'react';
import { css } from 'emotion';
import {
    Cards,
    CardsFiltering,
    CardsPagination,
} from '@amzn/awsui-components-react/polaris';
import {FormField, Textarea} from "@amzn/awsui-components-react";
import Linkify from 'react-linkify';
const pattern = new RegExp('{"fileName":.*,"downloadUrl":.*}');

let ticketDescription;
let editModeEnabled;

// Ticket History Panel
const TicketHistoryComponent = (props): ReactElement => {

    editModeEnabled = props.editModeEnabled;
    ticketDescription = props.ticket.comments[0];

    return (
        <div id="TicketHistoryContainer">
            <Cards
                cardsPerRow={[{ cards: 1 }]}
                cardDefinition={CARD_DEFINITIONS}
                items={props.ticket.comments}
                header={<h2>History</h2>}
                empty={
                    <div className="awsui-util-t-c">
                        <div className="awsui-util-pt-xs awsui-util-mb-xs">
                            <b>No history</b>
                        </div>
                        <p className="awsui-util-mb-s">No history to display</p>
                    </div>
                }
            >
                <CardsFiltering />
                <CardsPagination />
            </Cards>
        </div>

    );
};

const line_break_paragraph = css`
    white-space: pre-line;
`;

const handleChange = (e,comment) => {
    comment.commentText = e.detail.value;
};

// Schema of each correspondence card
const CARD_DEFINITIONS = {
    header: (item): ReactElement => (
        <small>
            {item.commentAuthor.authorName} commented on this issue {item.relativeCreateDate} ago
        </small>
    ),
    sections: [
        {
            id: 'content',
            header: (): string => '',
            content: (item): any => {
                if(pattern.test(item.commentText)){
                    let fileDetails = JSON.parse(item.commentText);
                    return (<a href={fileDetails.downloadUrl} target="_blank"> {fileDetails.fileName}</a>);
                }
                else if(item != ticketDescription){
                    return(
                        <div className={line_break_paragraph}>
                            <Linkify>{item.commentText}</Linkify>
                        </div>
                    );
                }
                return (
                    <div className={line_break_paragraph}>
                        {!editModeEnabled &&
                            <Linkify> {item.commentText} </Linkify>
                        }
                        {editModeEnabled &&
                            <FormField>
                                <Textarea id="commentText" onChange={(e) => handleChange(e,item)}
                                          value={item.commentText}/>
                            </FormField>
                        }
                    </div>
                );
            },
        },
    ],
};

export default TicketHistoryComponent;