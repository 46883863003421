import React, {useEffect, useState} from 'react';
import {AppLayout} from "@amzn/awsui-components-react";
import TicketListComponent from "./app/TicketList/TicketListComponent";
import NavigationModal from "./app/components/NavigationModal"
import TopBanner from "./app/components/TopBanner"
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import TicketListReducer from "./app/TicketList/TicketListReducer";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import {
    AuthenticatedSession,
    getAppHostConfig,
    SystemStatusFlash,
    useSession,
    withAuth, withRefreshIfStale, withSession
} from '@amzn/et-console-components';
import TicketDetailComponent from "./app/TicketDetail/TicketDetailComponent";
import {APIEndpointContext} from "./Context";
import {GetAPIEndpoint} from "./app/TicketAPIClient";
import Redirection from "./app/components/Redirection"
import {ErroredSession} from "@amzn/et-console-components/dist/hooks/useSession";

const store = createStore(TicketListReducer);
const {WEB_HOST_AND_PORT} = getAppHostConfig("");

console.info(`Web host: ${WEB_HOST_AND_PORT}`)


const  AuthenticatedApp = withAuth(()  => {

    const [apiEndpoint, setAPIEndpoint] = useState("");

    useEffect(() => {
        // Update apiEndpoint asynchronously
        GetAPIEndpoint().then((response) => {
            setAPIEndpoint(response)
        });
    });

    const session = useSession() as AuthenticatedSession;
    return (
        <div className="awsui">
        <APIEndpointContext.Provider value={apiEndpoint} >
            <div id="header">
                    <SystemStatusFlash session={session}/>
                    <TopBanner session={session} />
            </div>
            <Router>
                <Switch>
                    <Route path="/web/tickets/list">
                            <AppLayout
                                content={<Provider store={store}><TicketListComponent session={session}/></Provider>}
                                navigation={<NavigationModal/>}
                            />
                    </Route>
                    <Route path="/web/tickets/:ticketId">
                            <AppLayout
                                content={<TicketDetailComponent session={session}/>}
                                navigation={<NavigationModal/>}
                            />
                    </Route>
                    <Route path="/web">
                        <Redirection path="/web" />
                    </Route>
                </Switch>
            </Router>
        </APIEndpointContext.Provider>
        </div>);
});

const RootApp = withSession(({ session }) => {
        if ((session as ErroredSession).error) {
            // ErrorBoundary will give them a nice error page that gives them some
            // options (back to old UI, retry, etc)
            throw (session as ErroredSession).error;
        }

        return <AuthenticatedApp />
    });

// TODO: Need to add error boundary
export default () => (
        <RootApp/>
);
