export interface Ticket{
    assignedFolder?: string;
    atmsTicketId?: string;
    title?: string;
    description?: string;
    organizationId?: string;
    submitterId?: string;
    submitterName?: string;
    linguistId?: string;
    linguistName?: string;
    createDate?: string;
    status?: string;
    category?: string;
    categoryId?: string;
    lastUpdatedDate?: string;
    actionOwner?: string;
    action?: string;
    comments?: Comment[];
    relativeCreateDate?: string;
    relativeLastUpdatedDate?: string;
}

export interface Comment{
    CommentAuthor?: CommentAuthor;
    createDate?: string;
    lastUpdatedDate?: string;
    commentId?: string;
    commentText?: string;
}

export interface Template{
    templateId?:string;
    templateTitle?:string;
    description?:string;
}

export interface CommentAuthor{
    authorName?: string;
    authorId?: string;
    organizationId?: string;
}

export interface User{
    id: number;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    role: string;
    timezone: string;
    sysadminId: number | undefined;
    federateId: string;
    disableProjectSharing: boolean;
    machineTranslateSettingsDefaultId: number;
}

export interface CreateTicketRequest {
    submitterId: string;
    submitterName: string;
    submitterEmail: string;
    title: string;
    description: string;
    categoryId: string;
    categoryName: string;
    linguistId?: string;
    linguistName?: string;
    linguistEmail?: string;
}

export enum ATMSJobFields {
    projectName = 'Project Name',
    projectId = 'Project ID',
    jobTaskId = 'Job Task ID',
    jobPartId = 'Job Part ID',
    sourceLocale = 'Source Language',
    targetLocale = 'Target Language',
    dueDate = 'Due Date',
    workflowStep = 'Workflow Step'
}

export enum Roles {
    LINGUIST = "LINGUIST" ,
    PROJECT_MANAGER = "PROJECT_MANAGER",
    SYS_ADMIN = "SYS_ADMIN",
    ADMIN = "ADMIN"
}

export enum TicketStatus{
    OPEN = "Open",
    NEEDS_ACTION = "Needs action"
}