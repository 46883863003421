import React, {ReactElement} from "react";
import {Alert, Spinner} from "@amzn/awsui-components-react/polaris";
import ColumnLayout from "@amzn/awsui-components-react/polaris//column-layout";
import Badge from "@amzn/awsui-components-react/polaris//badge";
import {css} from "emotion";
import {RouteComponentProps, withRouter} from "react-router";
import {LINGUIST_TICKET_FOLDER} from "../types/constants"
import {Ticket, TicketStatus} from "../types/commonTypes"

const greyedText =  css`
 color: darkgrey !important;
`;

interface Props extends RouteComponentProps {
    tickets: Ticket[];
    areTicketsLoading: boolean;
    ticketLoadError?: string;
}

const TicketComponent = withRouter((props: Props): ReactElement => {
    const {tickets, areTicketsLoading, ticketLoadError} = props;

    function handleTicketClicked(ticket: any) {
        props.history.push(ticket.atmsTicketId);
    }

    function handleTicketStatusName(ticket: any){
        if(ticket.status == TicketStatus.OPEN && ticket.assignedFolder == LINGUIST_TICKET_FOLDER){return ticket.status + "- Internal"}
        else if(ticket.status == TicketStatus.OPEN && ticket.assignedFolder != LINGUIST_TICKET_FOLDER){return ticket.status + "- External"}
        else{return ticket.status}
    }

    if (areTicketsLoading) {
        return <Spinner size='large' />
    }

    if (ticketLoadError) {
        return <Alert type='error' >Failed to load tickets: {ticketLoadError}</Alert>
    }

    if(tickets.length == 0)
        return (<div>No tickets</div>);

    return (<div id="TicketsContainer">
        {tickets.map(ticket => (
            <div className="awsui-util-container" key={ticket.atmsTicketId} onClick={() => handleTicketClicked(ticket)}>
                <ColumnLayout columns={2} >
                    <div data-awsui-column-layout-root="true">
                        <div>
                            <div className="awsui-util-t-l">
                                <div>{ticket.title}</div>
                                <div> <p className={greyedText}>Category: {ticket.category} </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="awsui-util-t-r">
                                <div> <Badge color={ticket.status === TicketStatus.OPEN ? "blue": (ticket.status == TicketStatus.NEEDS_ACTION ? "red": "grey")}>{handleTicketStatusName(ticket)}</Badge></div>
                                <div> <p className={greyedText}> Submitted {ticket.relativeCreateDate} ago by {ticket.submitterName}</p></div>
                            </div>
                        </div>
                    </div>
                </ColumnLayout>
            </div>
        ))}
    </div>);
});

export default TicketComponent;