import React, {ReactElement, useCallback, useEffect, useReducer} from 'react';
import I18n from '../../utils/setupI18n';
import {BreadcrumbGroup, Button, Flash, Flashbar} from "@amzn/awsui-components-react";
import TicketMetadataComponent from "./TicketMetadataComponent";
import TicketHistoryComponent from './TicketHistoryComponent';
import TicketDetailApiClient from "./TicketDetailApiClient";
import {Ticket} from "../types/commonTypes";
import TicketDetailReducer from "./TicketDetailReducer";
import {APIEndpointContext} from "../../Context";
import {TicketCommentModal} from "../components/TicketCommentModal";
import {withRouter} from "react-router";
import FileAttachmentContainer from "./FileAttachmentContainer";
import {ESCALATION_MESSAGE,RESOLVED_MESSAGE} from "../types/constants"

const TicketDetailComponent = (props): ReactElement => {
    const apiEndpoint = React.useContext(APIEndpointContext);
    const showFlashBar = (props.location != undefined && props.location.state != undefined && props.location.state.showFlashBar != undefined)
        ? props.location.state.showFlashBar : false;
    const messageText = (props.location != undefined && props.location.state != undefined && props.location.state.message != undefined)
        ? props.location.state.message : "";
    const ticketDetailApiClient: TicketDetailApiClient = new TicketDetailApiClient();
    const currentPath = window.location.pathname;
    const ticketId = currentPath.substr(currentPath.lastIndexOf('/') + 1);
    const [state, dispatch] = useReducer(TicketDetailReducer, {
        isDisplayCommentModal: false,
        ticketStatus:"Open",
        ticketDTO: {comments: []},
        editMode: false,
        displayFlashbar: showFlashBar,
        flashbarText: messageText});
    const { isDisplayCommentModal,ticketStatus,ticketDTO , ticketCategories} = state;
    let originalTicketCategory = ticketDTO.category;
    const getTicketDetails = useCallback((ticketId) => {
        ticketDetailApiClient.getTicketDetail(ticketId).then(response => {
            let tempTicketDTO: Ticket = response;
            sortTicketsInCreationOrder(tempTicketDTO.comments);

            if(tempTicketDTO.description != undefined){
                let descriptionAsComment = {
                    atmsTicketId: tempTicketDTO.atmsTicketId,
                    commentAuthor: {
                        authorName: tempTicketDTO.submitterName
                    },
                    relativeCreateDate: tempTicketDTO.relativeCreateDate,
                    relativeLastUpdatedDate: tempTicketDTO.relativeLastUpdatedDate,
                    commentText: tempTicketDTO.description
                };
                if(tempTicketDTO.comments != null) {
                    tempTicketDTO.comments.unshift(descriptionAsComment);
                }
            }

            if(tempTicketDTO.category != undefined) {
                originalTicketCategory = tempTicketDTO.category;
            }

            dispatch({type:'ticketDetailsLoaded', ticketDTO: tempTicketDTO});
        });
    },[]);

    const loadTicketCategories = async () => {
        const categories = await ticketDetailApiClient.getTicketCategories()
        dispatch({type: "loadedTicketCategories", ticketCategories: categories})
    }

    const sortTicketsInCreationOrder = (comments): void => {
        comments.sort(function (a, b) {
            return +new Date(a.createDate) - +new Date(b.createDate);
        });
    }

    const handleCommentButtonClicked = (): void => {
        dispatch({type: "commentButtonClicked"})
    };

    const handleResolveButtonClicked = (): void => {
        dispatch({type: "resolveButtonClicked"});
    };

    const displayResolvedFlashbar = (): void => {
        dispatch({type: "toggleFlashbar",flashbarText: RESOLVED_MESSAGE})
    };

    const onDismissTicketCommentModal = (): void => {
        dispatch({type: "cancelCommentClicked"})
    };

    const handleToggleEditMode = (): void => {
        dispatch({type:'toggleEditMode', editMode: !state.editMode});
    }

    const handleSaveLinguistTicket = (): void => {
        dispatch({type:'toggleEditMode', editMode: !state.editMode});
        if(ticketDTO.comments != null && ticketDTO.comments[0] != null && ticketDTO.description != ticketDTO.comments[0].commentText){
            const descriptionParams = {
                newDescription: ticketDTO.comments[0].commentText
            };
            if(ticketDTO.atmsTicketId!=undefined){
                ticketDetailApiClient.updateTicketDescription(ticketDTO.atmsTicketId, JSON.stringify(descriptionParams))
                    .then(() => {
                        getTicketDetails(ticketDTO.atmsTicketId);
                    }).catch((error) => {
                    console.log("Unable to post comment. Error:" + error);
                });
            }
        }

        if(ticketDTO.category != null && ticketDTO.categoryId != null && ticketDTO.category != originalTicketCategory){
            const categoryParams = {
                category: ticketDTO.category,
                categoryId: ticketDTO.categoryId
            };
            if(ticketDTO.atmsTicketId != undefined){
                ticketDetailApiClient.updateTicketCategory(ticketDTO.atmsTicketId, JSON.stringify(categoryParams))
                    .then(() => {
                        getTicketDetails(ticketDTO.atmsTicketId);
                    }).catch((error) => {
                    console.log("Unable to update category. Error:" + error);
                });
            }
        }
    }

    const handleSubmitTicket = (): void => {
        if(ticketDTO.category != null){
            const categoryParams = {
                categoryId: ticketDTO.categoryId,
                submitterId: props.session.user.id.toString(),
                submitterName: props.session.user.userName,
                submitterEmail: props.session.user.email
            };
            if(ticketDTO.atmsTicketId != undefined){
                ticketDetailApiClient.submitTicket(ticketDTO.atmsTicketId, JSON.stringify(categoryParams))
                    .then(() => {
                        getTicketDetails(ticketDTO.atmsTicketId);
                        dispatch({type: "toggleFlashbar",flashbarText: ESCALATION_MESSAGE})
                    }).catch((error) => {
                    console.log("Unable to update category. Error:" + error);
                });
            }
        }
    }

    const items = [{
        "text": I18n.t("ATMS"),
        "href": "/web"
    },{
        "text": I18n.t("Support"),
        "href": "/web/tickets/list"
    },{
        "text": I18n.t(ticketId),
        "href": "#"
    }];

    useEffect(() => {
        getTicketDetails(ticketId);
        loadTicketCategories();
    }, [ticketId]);


    return (
        <div id="TicketDetailContainer">

            <Flash visible={state.displayFlashbar} type="success" dismissible={true}>
                {state.flashbarText}
            </Flash>

            <BreadcrumbGroup label={I18n.t("Breadcrumbs")} items={items}></BreadcrumbGroup>
            <TicketMetadataComponent ticket={ticketDTO}
                                     session={props.session}
                                     handleResolveFunction={handleResolveButtonClicked}
                                     handleCommentFunction={handleCommentButtonClicked}
                                     editModeEnabled={state.editMode}
                                     handleToggleEditFunction={handleToggleEditMode}
                                     handleSaveLinguistTicket={handleSaveLinguistTicket}
                                     handleSubmitTicket={handleSubmitTicket}
                                     ticketCategories={ticketCategories}
            />
            {ticketDTO.status != 'Resolved' &&
            <div className='awsui-util-mb-m awsui-util-mt-xs'>
                <div className='awsui-util-action-stripe-large'>
                    <div className='awsui-util-action-stripe-title'>
                        <h1></h1>
                    </div>
                    <div className='awsui-util-action-stripe-group'>
                        <div className="awsui-util-mt-m" style={{marginLeft:"10px"}}>
                            <label htmlFor="fileAttachment" className="awsui-button awsui-button-variant-normal awsui-hover-child-icons" awsui-button-region="text">Add Attachment</label>
                            <FileAttachmentContainer ticket={ticketDTO} updateHistorySection={getTicketDetails}/>
                        </div>
                        <div style={{marginLeft:"10px"}}>
                            <Button id="newComment" className="awsui-util-mt-m " variant="primary" text={I18n.t("Add Comment")} onClick={handleCommentButtonClicked}/>
                        </div>
                    </div>
                </div>
            </div>
            }
            <TicketHistoryComponent ticket={ticketDTO} dispatch={dispatch} editModeEnabled={state.editMode}/>
            <TicketCommentModal session = {props.session}
                                modalId={`TicketCommentModal`}
                                submitButtonId={`SubmitButton`}
                                visible={isDisplayCommentModal}
                                onDismiss={onDismissTicketCommentModal}
                                updateHistorySection={getTicketDetails}
                                newTicketStatus={ticketStatus}
                                displayFlashbar={displayResolvedFlashbar}
            />
        </div>
    );
};
export default withRouter(TicketDetailComponent);