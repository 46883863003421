import React from 'react';
import { Icon as PolarisIcon } from '@amzn/awsui-components-react/polaris';
import {
    MdFormatQuote,
    MdStorage,
    MdRepeat,
    MdLibraryBooks,
    MdPerson,
    MdDelete,
    MdDragHandle,
} from 'react-icons/md';
const MD_ICONS = {
    MdFormatQuote,
    MdStorage,
    MdRepeat,
    MdLibraryBooks,
    MdPerson,
    MdDelete,
    MdDragHandle,
};

/**
 * Passthrough to Polaris' Icon with support for:
 *  * Material Design icons (explicitly imported above)
 *  * Icon rotation
 */
const Icon = props => {
    // TODO: support size, variant, alt
    let { name = '' } = props;
    let rotate = null;
    if (name.includes('@')) {
        [name, rotate] = name.split('@');
    }

    let element;
    if (name in MD_ICONS) {
        const MdIcon = MD_ICONS[name];
        element = <MdIcon size={24} />;
    } else {
        element = <PolarisIcon {...props} name={name} />;
    }
    if (rotate) {
        element = <span css={{ flexShrink: 0, transform: `rotate(${rotate}deg)` }}>{element}</span>;
    }
    return element;
};

Icon.for = type => {
    switch (type) {
        case 'termbase':
            return 'MdFormatQuote';
        case 'transmemory':
            return 'MdStorage';
        case 'project':
        case 'shared-project':
            return 'MdRepeat@-45';
        default:
            return type;
    }
};

export default Icon;
