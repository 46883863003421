import React, {ReactElement, useCallback, useEffect, useReducer} from "react";
import {Tabs} from '@amzn/awsui-components-react';
import TicketComponent from "./TicketComponent";
import I18n from '../../utils/setupI18n';
import TicketListReducer from "./TicketListReducer";
import {Ticket, Roles} from '../types/commonTypes';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {LINGUIST_TICKET_FOLDER} from "../../app/types/constants";
import TicketListApiClient from "./TicketListApiClient";
import {AuthenticatedSession} from "@amzn/et-console-components";
import {Alert} from "@amzn/awsui-components-react/polaris";

const TicketTabsContainer = (props): ReactElement =>{
    const ticketAPI: TicketListApiClient = new TicketListApiClient();
    const [state, dispatch] = useReducer(TicketListReducer, {areTicketsLoading: true, tickets: [], currentView: "myTickets"});
    const {tickets, currentView, areTicketsLoading, ticketLoadError} = state;
    let session: AuthenticatedSession = props.session;
    const userId = session.user.id.toString();
    const organizationId = session.organization.id.toString();
    const role = session.user.role;

    const filterMyTickets = createSelector(
        state => tickets,
        tickets => role==Roles.LINGUIST
            ? tickets.filter(ticket => (ticket.submitterId == userId || ticket.linguistId == userId))
            : tickets.filter(ticket => ticket.assignedFolder != LINGUIST_TICKET_FOLDER &&
                (ticket.submitterId == userId || ticket.linguistId == userId))
    );

    const filterAllLinguistTickets = createSelector(
        state => tickets,
        tickets => role==Roles.LINGUIST
            ? tickets.filter(ticket => ticket.assignedFolder == LINGUIST_TICKET_FOLDER && ticket.submitterId == userId)
            : tickets.filter(ticket => ticket.assignedFolder == LINGUIST_TICKET_FOLDER && ticket.organizationId == organizationId)
    );

    const filterNonLinguistTickets = createSelector(
        state => tickets,
        tickets =>  tickets.filter(ticket => ticket.assignedFolder != LINGUIST_TICKET_FOLDER)
    );

    const myTickets = useSelector(filterMyTickets);
    const allLinguistTickets = useSelector(filterAllLinguistTickets);
    const allTickets = useSelector(filterNonLinguistTickets);

    const handleTabsClicked = (event): void => {
        dispatch({type: event.detail.activeTabId+"Clicked"})
    };

    const getTickets = useCallback(() => {
        ticketAPI.GetTicketsApi().then(response => {
            let dataFromServer: Ticket[] = response;
            dispatch({type:'ticketsReceived', tickets: dataFromServer});
        }).catch(e => {
            dispatch({type: "ticketLoadFailed", error: e})
        });
    },[]);

    useEffect(() => {
        getTickets();
    }, []);

    return (
        <div id="TicketsTabContainer">
            <Alert type={'info'} content={"Lists tickets opened in the past 30 days you have access to"} />
            {role == Roles.LINGUIST &&
            <Tabs id="TicketTabs" tabs={[
                {
                    label: I18n.t("My tickets"),
                    id: "myTickets",
                    content: <TicketComponent tickets={myTickets} areTicketsLoading={areTicketsLoading} ticketLoadError={ticketLoadError}/>

                },
                {
                    label: I18n.t("All tickets"),
                    id: "allTickets",
                    content: <TicketComponent tickets={allTickets} areTicketsLoading={areTicketsLoading} ticketLoadError={ticketLoadError}/>
                }
            ]
            } activeTabId={currentView} onChange={handleTabsClicked}>
            </Tabs>
            }
            {role != Roles.LINGUIST &&
            <Tabs id="TicketTabs" tabs={[
                {
                    label: I18n.t("My tickets"),
                    id: "myTickets",
                    content: <TicketComponent tickets={myTickets} areTicketsLoading={areTicketsLoading} ticketLoadError={ticketLoadError} />

                },
                {
                    label: I18n.t("Linguist tickets"),
                    id: "allLinguistTickets",
                    content: <TicketComponent tickets={allLinguistTickets} areTicketsLoading={areTicketsLoading} ticketLoadError={ticketLoadError}/>,
                },
                {
                    label: I18n.t("All tickets"),
                    id: "allTickets",
                    content: <TicketComponent tickets={allTickets} areTicketsLoading={areTicketsLoading} ticketLoadError={ticketLoadError}/>
                }
            ]
            } activeTabId={currentView} onChange={handleTabsClicked}>
            </Tabs>
            }
        </div>
    );

};

export default TicketTabsContainer;