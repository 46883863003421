import React, {ReactElement, useEffect, useReducer} from 'react';
import {StandardModal} from './StandardModal';
import {Form, FormField, ColumnLayout, Textarea} from '@amzn/awsui-components-react/polaris';
import I18n from '../../utils/setupI18n';
import TicketCommentModalReducer from "./TicketCommentModalReducer";
import TicketDetailApiClient from "../TicketDetail/TicketDetailApiClient";
import {User} from "../types/commonTypes";
import {Flash} from "@amzn/awsui-components-react";
import {AuthenticatedSession} from "@amzn/et-console-components";

interface Props {
    modalId?: string;
    submitButtonId?: string;
    visible: boolean;
    onDismiss: () => void;
    session: AuthenticatedSession;
    updateHistorySection: (any) => void;
    newTicketStatus: string;
    displayFlashbar: () => void;
}

export const TicketCommentModal = (
    {
        modalId,
        submitButtonId,
        visible,
        onDismiss,
        session,
        updateHistorySection,
        newTicketStatus,
        displayFlashbar
    }: Props): ReactElement => {

    const ticketDetailApi: TicketDetailApiClient = new TicketDetailApiClient();

    let user: User = session.user as User;

    const comment = {
        authorName: "",
        commentText: "",
        commentType: "text",
        ticketStatus: "Open",
        flashBar: false,
        isSubmitting: false
    };

    const [state, dispatch] = useReducer(TicketCommentModalReducer, comment);

    //TODO: We don't have any tests for this Modal or API call
    //https://sim.amazon.com/issues/ETI-1752
    useEffect(() => {
        dispatch({type: "authorInfoSubmitted", authorName: user.userName})},[session]);

    const currentPath = window.location.pathname;
    const ticketId = currentPath.substr(currentPath.lastIndexOf('/') + 1);

    const handleChange = (e) => {
        dispatch({type: "toggleFlashOff"})
        dispatch({type: "commentChanged", commentText: e.detail.value});
    };

    const handleCancelClick = (): void => {
        onDismiss();
    };

    const handleSubmitClick = (e): void => {
        dispatch({type: "commentSubmitting"});
        state.ticketStatus = newTicketStatus;

        e.preventDefault();
        if(state.commentText.trim().length > 0){
            postComment();
            onDismiss();
            dispatch({type: "commentSubmitted"});
        }
        else{
            dispatch({type: "toggleFlashOn"})
        }
    };

    const postComment = (): void => {
        let data = JSON.stringify(state);
        ticketDetailApi.postTicketComment(ticketId, data)
            .then(() => {
                updateHistorySection(ticketId);
                if(state.ticketStatus=="Resolved"){displayFlashbar()}
            }).catch((error) => {
            console.log("Unable to post comment. Error:" + error);
        });
    }

    return (
        <StandardModal
            id={modalId}
            header={I18n.t('Add Comment')}
            content={
                <Form>
                    <Flash visible={state.flashBar} type="error" >
                        Comment must not be empty
                    </Flash>
                    <ColumnLayout>
                        <div data-awsui-column-layout-root="true">
                            <FormField>
                                <Textarea id="commentText" onChange={handleChange} value={state.commentText}/>
                            </FormField>
                        </div>
                    </ColumnLayout>
                </Form>
            }
            visible={visible}
            handleCancelClick={handleCancelClick}
            handleSubmitClick={handleSubmitClick}
            submitButtonId={submitButtonId}
            loading={state.isSubmitting}
        />
    );
};