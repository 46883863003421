import axios from 'axios';

// Get Tickets API Service endpoint
export const GetAPIEndpoint = async (): Promise<string> => {

    // For local run, please use URL 'stage_config/beta/config/config.json'
    return await axios.get('/config/config.json')
        .then(function (response) {
            // handle success
            console.log(response.data.apiEndpoint)
            return response.data.apiEndpoint;
        })
        .catch(function (error) {
            // handle error
            console.error(error);
            return Promise.reject(error)
        });
};