import React, { ReactElement } from 'react';
import { Modal, Button, CustomDetailEvent } from '@amzn/awsui-components-react/polaris';
import I18n from '../../utils/setupI18n';

export interface Props {
    id?: string;
    content: ReactElement;
    header: ReactElement;
    submitButtonId?: string;
    visible: boolean;
    handleCancelClick: (e: CustomDetailEvent<Button.ClickDetail | Modal.DismissDetail>) => void;
    handleSubmitClick: (e: CustomDetailEvent<Button.ClickDetail>) => void;
    loading: boolean;
}

export const StandardModal = ({
                                  id,
                                  header,
                                  content,
                                  visible,
                                  submitButtonId,
                                  handleCancelClick,
                                  handleSubmitClick,
                                  loading,
                              }: Props): ReactElement => {
    return (
        <Modal
            id={id}
            header={header}
            content={content}
            visible={visible}
            onDismiss={handleCancelClick}
            expandToFit={true}
            footer={
                <span className="awsui-util-f-r">
          <Button variant="link" text={I18n.t('Cancel')} onClick={handleCancelClick} />
          <Button id={submitButtonId} variant="primary" text={I18n.t('Submit')} onClick={handleSubmitClick} disabled={loading} loading={loading}/>
        </span>
            }
        />
    );
};
